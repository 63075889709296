import React from 'react';

const Contact = () => {
    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <h1 sx={{color: "black"}}>Contact</h1>
        </div>
    )
};

export default Contact